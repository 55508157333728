:root {
    --df-messenger-primary-color: #0b57d0;
    --df-messenger-border-color: #c4c7c5;
    --df-messenger-element-font-color: #444746;
    --df-messenger-chat-border: 1px solid var(--df-messenger-border-color);
    --df-messenger-chat-border-radius: 0;
    --df-messenger-chat-background: #f3f6fc;
    --df-messenger-chat-padding: 24px 24px 8px 24px;
    --df-messenger-chat-scroll-button-enabled-display: flex;
    --df-messenger-chat-scroll-button-align: flex-end;
    --df-messenger-chat-scroll-button-container-padding: 8px 16px;
    --df-messenger-chat-scroll-button-background: #a8c7fa;
    --df-messenger-chat-scroll-button-font-color: #041e49;
    --df-messenger-chat-scroll-button-text-display: none;
    --df-messenger-chat-scroll-button-padding: 8px;
    --df-messenger-chat-function-call-button-background: #a8c7fa;
    --df-messenger-chat-function-call-button-font-color: #041e49;
    --df-messenger-chat-window-height: 60vh;
    --df-messenger-chat-padding-minimized: 8px;
    --df-messenger-chat-bubble-background: #f3f6fc;
    --df-messenger-chat-bubble-icon-color: #444746;
    --df-messenger-focus-color-contrast: var(--df-messenger-focus-color, var(--df-messenger-default-focus-color));
    --df-messenger-titlebar-background: #fff;
    --df-messenger-titlebar-font-color: #000;
    --df-messenger-titlebar-border-bottom: 1px solid var(--df-messenger-border-color);
    --df-messenger-titlebar-icon-font-color: var(--df-messenger-icon-font-color, var(--df-messenger-default-icon-font-color));
    --df-messenger-titlebar-subtitle-font-color: var(--df-messenger-secondary-font-color, var(--df-messenger-default-secondary-font-color));
    --df-messenger-titlebar-padding: 10px 24px;
    --df-messenger-titlebar-title-font-size: 22px;
    --df-messenger-titlebar-title-line-height: 28px;
    --df-messenger-titlebar-subtitle-font-size: 12px;
    --df-messenger-titlebar-subtitle-line-height: 16px;
    --df-messenger-titlebar-button-background-hover: rgba(68, 71, 70, 0.08);
    --df-messenger-titlebar-button-color-inactive: var(--df-messenger-icon-font-color, var(--df-messenger-default-icon-font-color));
    --df-messenger-titlebar-button-background-active: #f1f4f8;
    --df-messenger-titlebar-button-color-active: var(--df-messenger-primary-color, var(--df-messenger-default-primary-color));
    --df-messenger-message-padding: 16px;
    --df-messenger-message-bot-background: #fff;
    --df-messenger-message-user-background: #d3e3fd;
    --df-messenger-message-border-radius: 16px;
    --df-messenger-message-bot-border-top-left-radius: 0;
    --df-messenger-message-bot-stack-border-top-left-radius: 0;
    --df-messenger-message-bot-stack-border-bottom-left-radius: 0;
    --df-messenger-message-user-border-top-right-radius: 0;
    --df-messenger-message-user-stack-border-top-right-radius: 0;
    --df-messenger-message-user-stack-border-bottom-right-radius: 0;
    --df-messenger-message-stack-spacing: 4px;
    --df-messenger-input-long-text-warning-display: block;
    --df-messenger-input-box-background: #fff;
    --df-messenger-input-padding: 16px 8px 24px 24px;
    --df-messenger-input-background: #f3f6fc;
    --df-messenger-input-border-top: none;
    --df-messenger-input-box-border: 1px solid var(--df-messenger-border-color);
    --df-messenger-input-box-border-radius: 999px;
    --df-messenger-input-box-padding: 16px 32px;
    --df-messenger-input-box-focus-border: 2px solid var(--df-messenger-primary-color);
    --df-messenger-input-box-focus-padding: 15px 31px;
    --df-messenger-send-icon-offset-y: 2px;
    --df-messenger-link-decoration: none;
    --df-messenger-link-font-color: var(--df-messenger-primary-color);
    --df-messenger-link-visited-font-color: var(--df-messenger-primary-color);
    --df-messenger-link-hover-font-color: var(--df-messenger-primary-color);
    --df-messenger-link-background-color: #fff;
    --df-messenger-link-padding: 2px 6px;
    --df-messenger-link-border: 1px solid var(--df-messenger-primary-color);
    --df-messenger-link-border-radius: 4px;
    --df-messenger-table-border-color: #e6e6e6;
    --df-messenger-table-padding: 12px 16px;
    --df-messenger-table-font-size: 14px;
    --df-messenger-table-border-radius: 16px;
    --df-messenger-table-header-background: #f2f2f2;
    --df-messenger-table-header-padding: 12px 16px;
    --df-messenger-table-header-font-size: 14px;
    --df-messenger-table-header-font-weight: 500;
    --df-messenger-table-header-border-top: 1px solid var(--df-messenger-table-border-color);
    --df-messenger-table-header-border-bottom: 1px solid var(--df-messenger-table-border-color);
    --df-messenger-table-header-border-left-first: 1px solid var(--df-messenger-table-border-color);
    --df-messenger-table-header-border-right-last: 1px solid var(--df-messenger-table-border-color);
    --df-messenger-table-border-bottom: 1px solid var(--df-messenger-table-border-color);
    --df-messenger-table-border-left-first: 1px solid var(--df-messenger-table-border-color);
    --df-messenger-table-border-right-last: 1px solid var(--df-messenger-table-border-color);
    --df-messenger-card-box-shadow: none;
    --df-messenger-card-border: none;
    --df-messenger-card-border-radius: 16px;
    --df-messenger-card-border-top-left-radius: 0;
    --df-messenger-card-stack-border-bottom-left-radius: 0;
    --df-messenger-card-stack-border-top-left-radius: 0;
    --df-messenger-chips-box-shadow: none;
    --df-messenger-chips-font-color: var(--df-messenger-element-font-color);
    --df-messenger-chips-icon-font-color: var(--df-messenger-element-font-color);
    --df-messenger-chips-border-radius: 8px;
    --df-messenger-files-box-shadow: none;
    --df-messenger-citations-font-color: var(--df-messenger-element-font-color);
    --df-messenger-citations-border-color: var(--df-messenger-border-color);
    --df-messenger-citations-border-radius: 999px;
    --df-messenger-citations-padding: 4px 16px;
    --df-messenger-citations-icon-offset: -8px;
    --df-messenger-citations-icon-font-size: 24px;
    --df-messenger-citations-message-display: none;
    --df-messenger-button-border: 1px solid var(--df-messenger-border-color);
    --df-messenger-button-padding: 8px;
    --df-messenger-image-border-radius: 16px;
    --df-messenger-video-border-radius: 16px;
    --df-messenger-video-border: 1px solid var(--df-messenger-border-color);
    --df-messenger-list-padding: 16px;
    --df-messenger-message-rich-feedback-spacing: 16px;
    --df-messenger-message-rich-feedback-padding: 16px;
    --df-messenger-message-rich-feedback-background: #fff;
    --df-messenger-message-rich-feedback-border-radius: 16px;
    --df-messenger-message-rich-feedback-chips-spacing: 8px;
    --df-messenger-message-rich-feedback-chips-background: #fff;
    --df-messenger-message-rich-feedback-chips-background-active: rgba(68, 71, 70, 0.12);
    --df-messenger-message-rich-feedback-chips-font-color: var(--df-messenger-element-font-color);
    --df-messenger-message-rich-feedback-chips-font-color-active: var(--df-messenger-element-font-color);
    --df-messenger-message-rich-feedback-submit-align: flex-start;
    --df-messenger-message-rich-feedback-submit-font-color: var(--df-messenger-element-font-color);
    --df-messenger-message-feedback-icon-border: var(--df-messenger-default-border);
    --df-messenger-message-feedback-icon-border-radius: 8px;
    --df-messenger-message-feedback-icon-background: #fff;
    --df-messenger-message-feedback-icon-padding: 8px;
    --df-messenger-message-feedback-icon-font-color-active: var(--df-messenger-primary-color);
    --df-messenger-message-feedback-icon-background-hover: rgba(68, 71, 70, 0.08);
}



/*# sourceMappingURL=df-messenger-default.css.map */
